@import "../../styles/variables.scss";

.wrapper {
  position: relative;
  height: 500px;
  width: 100%;
  display: flex;
  z-index: 10;
  // flex-direction: row;
  .tvWrapper {
    position: relative;
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 2%;
    max-width: 1000px;
    .tvFrame {
      position: relative;
      width: 100%;
      z-index: 10;
      left: 5px;
      top: 5px;
      min-width: 700px;
      .frameHold {
        position: relative;
        width: 100%;
        z-index: 3;
        pointer-events: none;
        * {
          pointer-events: none;
        }
      }
      .tvBG {
        position: absolute;
        width: 88%;
        height: 84%;
        top: 6%;
        left: 5%;
        z-index: -1;
        .mediawrap {
          position: relative;
          width: 100%;
          height: 100%;
          video {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
      }
    }
  }
  .colWrapper {
    position: relative;
    height: 100%;
    width: 20%;
    min-width: 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: 25%;

    .header {
      h1 {
        color: white;
        text-align: center;
        max-width: 300px;
      }
    }
    .subText {
      position: relative;
      max-width: 450px;

      p {
        color: white;
        text-align: center;
        line-height: 25px;
        margin: 0 auto 30px auto;
        @include regular-p;
      }
    }
    .button {
      display: flex;
      justify-content: center;
    }
  }
}

@media all and (max-width: 767px) {
  .wrapper {
    width: 100%;
    height: auto;
    flex-direction: column;
    .tvWrapper {
      width: calc(100% - 70px);
      order: 2;
      right: auto;
      margin: 50px auto 0 auto;
      .tvBG {
        width: 86%;
        min-width: unset;
      }
      .tvFrame {
        width: 100%;
        min-width: unset;
        top: auto;
        left: auto;
      }
    }
    .colWrapper {
      order: 1;
      width: calc(100% - 70px);
      margin: 0 auto;
      top: auto;
      min-width: unset;
      .button {
        display: none;
      }
    }
  }
}
