@import "./styles/variables.scss";

.notfound {
    position: relative;
    width: 100%;
    min-height: calc(100vh - 200px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .inner {
        position: relative;
        width: 85%;
        max-width: 960px;
        margin: 0 auto 0 7.5%;
        display: flex;
        flex-direction: column;
        z-index: 100;
        h1 {
        color: #fff;
        font-size: 4.5rem;
        margin-bottom: 10px;
        }
        p {
        color: #fff;
        max-width: 600px;
        @include regular-p;
        }
        a {
            color: $blue;
            text-decoration: underline;
        }
    }
}

@media all and (max-width: 896px) {
    .notfound {
        .inner {
            h1 {
                font-size: 2.5rem;
            }
        }
    }
}