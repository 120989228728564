@import "../../styles/variables.scss";

.wrapper {
  position: relative;
  width: 100%;
  min-height: 550px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px auto;
  .header {
    position: relative;
    display: flex;
    justify-content: center;
    max-width: 750px;
    h1 {
      color: white;
      text-align: center;
      font-size: 5rem;
      margin-bottom: 25px;
    }
  }
  .buttons {
    display: flex;
    justify-content: center;
    .button {
      padding-left: 20px;
    }
  }
  .subText {
    display: flex;
    justify-content: center;
    width: 50%;
    padding-top: 30px;

    p {
      color: white;
      text-align: center;
      @include regular-p;
    }
  }
}

@media all and (max-width: 767px) {
  .wrapper {
    min-height: unset;
    margin: 50px auto;
    .header {
      h1 {
        font-size: 3.5rem;
      }
    }
    .buttons {
      flex-direction: column;
      align-items: center;
      .button {
        padding: 0;
        margin-bottom: 30px;
        &:last-child {
          margin-bottom: 0;
        }
      }
    }
    .subText {
      width: calc(100% - 70px);
    }
  }
}
