@import "../../styles/variables.scss";

.rewardsWrapper {
  position: relative;
  display: flex;
  justify-content: center;
  margin: 100px auto;
  .card {
    position: relative;
    height: 100%;
    width: 100%;
    padding-bottom: 200px;
    display: flex;
    justify-content: center;
    .cardOverlay {
      position: relative;
      background-color: white;
      width: 60vw;
      max-width: 900px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      top: 200px;
      margin-right: 20px;
      z-index: 1;
      padding: 70px 0;
      .cardLeft {
        position: relative;
        width: 60%;
        .headerImage {
          position: relative;
          width: 80%;
          display: flex;
        }
        .subText {
          p {
            @include regular-p;
          }
        }
      }
      .cardRight {
        position: relative;
        width: 20%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        .imgwrap {
          position: relative;
          width: 45%;
          .fg {
            position: absolute;
            bottom: -3%;
            left: 14%;
            width: 73%;
            height: 100%;
            .inner {
              position: absolute;
              width: 100%;
              height: 100%;
              bottom: 0;
              clip-path: inset(70% 0% 0% 0%);
            }
          }
        }
      }
    }
    .cardUnderlay {
      position: absolute;
      background-color: $yellow;
      width: 60vw;
      max-width: 900px;
      height: 70%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 30px;
      top: 32.5%;
      left: 50.5%;
      z-index: 0;
      transform: translateX(-50%);
    }
  }
  .visaCard {
    position: absolute;
    width: 21%;
    max-width: 300px;
    top: 200px;
    left: 45vw;
    transform: translate(-80%, -50%);
    .imgwrap {
      position: relative;
      width: 100%;
    }
  }
}

@media all and (max-width: 767px) {
  .rewardsWrapper {
    margin: 0px auto 100px auto;
    .card {
      .cardOverlay {
        width: calc(100% - 70px);
        margin-right: 0;
        flex-direction: column;
        padding: 30px 0;
        .cardLeft {
          width: 80%;
          margin: 0 auto;
          .headerImage {
            width: 90%;
          }
        }
        .cardRight {
          display: none;
          position: absolute;
          bottom: 25px;
          right: 15px;
          width: 100px;
        }
      }
      .cardUnderlay {
        width: calc(100% - 70px);
        top: 31.5%;
        left: 52.5%;
      }
    }
    .visaCard {
      width: 55%;
      left: 65vw;
    }
  }
}
