// FONTS
@font-face {
  font-family: "Maax-Loop-Bold";
  src: url("/fonts/Maax-Loop-Bold.woff2") format("woff2"),
    url("/fonts/Maax-Loop-Bold.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@font-face {
  font-family: "Maax-Loop-Regular";
  src: url("/fonts/Maax-Loop-Regular.woff2") format("woff2"),
    url("/fonts/Maax-Loop-Regular.woff") format("woff");
  font-style: normal;
  font-weight: 400;
}

@mixin regular-p {
  font-family: $maax-regular;
  font-size: 1.4rem;
  line-height: 1.5;
  strong,
  b {
    font-family: $maax-bold;
    font-weight: 400;
  }
  @media all and (max-width: 767px) {
    font-size: 1.2rem;
  }
}

@mixin small-p {
  font-family: $maax-regular;
  font-size: 1.2rem;
  line-height: 1.5;
  strong,
  b {
    font-family: $maax-bold;
    font-weight: 400;
  }
  @media all and (max-width: 767px) {
    font-size: 1rem;
  }
}

$maax-regular: "Maax-Loop-Regular";
$maax-bold: "Maax-Loop-Bold";

// COLORS
$yellow: #fccc12;
$blue: #4a90e2;
$black: #1b1b1b;
$lightgray: #e3e3e3;
$darkgray: #707070;
$green: #33c486;
$graytext: #848484;
$lightred: #ff5a5a;
$lightgreen: #33c486;
